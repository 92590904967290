import { useState, useEffect } from "react";
import { 
  Nav, 
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";

import Login from "./Login";
import Register from "./Register";
import LoadingOverlay from 'react-loading-overlay';

export default function LoginRegister(props) {
  const [activeTab, setActiveTab] = useState("login");
  const [loadLogin, setLoadLogin] = useState(false);
  const [loadRegister, setLoadRegister] = useState(false);
  const [verifyAccount, setVerifyAccount] = useState(null);

  useEffect(() => {
    if (props.activeTab) {
      setActiveTab(props.activeTab);
    }
  }, []);

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const startLoadLogin = () => setLoadLogin(true);
  const stopLoadLogin = () => setLoadLogin(false);

  const startLoadRegister = () => setLoadRegister(true);
  const stopLoadRegister = () => setLoadRegister(false);

  const onVerifyAccount = ({type, value}) => {
    setVerifyAccount({type, value});
    toggle("login");
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === "login" ? "active" : ""}
            onClick={() => {
              toggle("login");
            }}
          >
            Login
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "register" ? "active" : ""}
            onClick={() => {
              toggle("register");
            }}
          >
            Register
          </NavLink>
        </NavItem>
      </Nav>
     
      <TabContent activeTab={activeTab}>
        <TabPane tabId="login">
          <Row>
            <Col sm="12">
              <LoadingOverlay
                spinner
                text='Loading...'
                active={loadLogin}
              >
                <Login 
                  verifyAccount={verifyAccount}
                  onStartLoad={startLoadLogin}
                  onStopLoad={stopLoadLogin}
                  onTab={toggle} 
                  onToggle={props.onToggleLogin} 
                />
              </LoadingOverlay>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="register">
          <Row>
            <Col sm="12">
              <LoadingOverlay
                spinner
                text='Loading...'
                active={loadRegister}
              >
                <Register
                  onVerifyAccount={onVerifyAccount}
                  onStartLoad={startLoadRegister}
                  onStopLoad={stopLoadRegister}
                  onTab={toggle}
                  onToggleTerms={props.onToggleTerms} 
                  onTogglePrivacy={props.onTogglePrivacy}
                />
              </LoadingOverlay>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}