import React, { Component } from "react";
import Tagline from "./Tagline";

// Scroll up button
import ScrollUpButton from "react-scroll-up-button";

//Import Switcher
import ThemeSwitcher from "./ThemeSwitcher";

//Import Icons
import FeatherIcon from "feather-icons-react";

import Topbar from "./Topbar";
import Footer from "./Footer";


const CustomDot = () => {
  return (
    <React.Fragment>
      <FeatherIcon icon="arrow-up" className="icons" />
    </React.Fragment>
  );
};


const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

class Layout extends Component {
  render() {
    const tagLineContent = null;
    return (
      <React.Fragment>
        {( <Topbar tagline={tagLineContent} hasDarkTopBar={this.props.hasDarkTopBar} logo={this.props.tenantData.logo}/> )}

        {this.props.children}

        {<Footer />}

        {/*<div className="btn btn-icon btn-soft-primary back-to-top">*/}
        {/* scrollup button */}
        {/*  <ScrollUpButton
            ContainerClassName="classForContainer"
            style={{ height: 36, width: 36 }}
            TransitionClassName="classForTransition"
          >
            <CustomDot />
          </ScrollUpButton>
        </div>*/}

        {/* theme switcher */}
        {/* <ThemeSwitcher /> */}
      </React.Fragment>
    );
  }
}

export default Layout;
