import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Label,
  Button,
  Card,
  CardBody,
} from "reactstrap";


import { AvForm, AvField } from "availity-reactstrap-validation";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import FeatherIcon from "feather-icons-react";
import { authApi } from "../api/authApi";

import LoginByEmail from "./LoginByEmail";
import LoginByMobile from "./LoginByMobile";
import { NotifyContainer, notifyError, notifyInfo } from "../components/Shared/Notify";

export default function Login(props) {
  const [screen, setScreen] = useState ("intro-login");
  const [phoneNoOrEmail, setPhoneNoOrEmail] = useState(null);
  const [pinOrPassword, setPinOrPassword] = useState(null);
  const [emailForReset, setEmailForReset] = useState(null);

  useEffect(() => {
    if (props.verifyAccount?.type === "email") {
      setPhoneNoOrEmail(props.verifyAccount.value);
      setScreen("email-login");
    }
    if (props.verifyAccount?.type === "mobile") {
      setPhoneNoOrEmail(props.verifyAccount.value);
      setScreen("phone-login");
    }
  }, [props.verifyAccount, screen, phoneNoOrEmail]);

  const handleLoginSelection = (event, errors, values) => {
    if (errors.length === 0)
    {
      var containEmailRegex = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
      
      setPinOrPassword(null);
      const isEmail = containEmailRegex.test(phoneNoOrEmail);

      if (isEmail) {
        setScreen("email-login");
      }
      else {
        authApi.getOTP(phoneNoOrEmail).then(
          (otp) => { 
            if (otp && otp.result === true) {
              setScreen("phone-login"); 
            }
            else {
              notifyError(`Your mobile no. ${phoneNoOrEmail} cannot be found.`);
            }
          },
          (error) => { notifyError(error); }
        );
      }
    }
  }

  const handleLoginEmail = (event, errors, values) => { 
    if (errors.length === 0) {
      props.onStartLoad();
      authApi.login(phoneNoOrEmail, pinOrPassword).then(
        (user) => {
          localStorage.setItem("userProfile", JSON.stringify(user));
          window.location.reload();
          props.onStopLoad();
          props.onToggle();
        },
        (error) => {
          props.onStopLoad();
          notifyError(error);
        }
      )
    }
  };

  const handleLoginPhone = () => { 
    if (pinOrPassword && pinOrPassword !== "")
    {
      props.onStartLoad();
      authApi.login(phoneNoOrEmail, pinOrPassword).then(
        (user) => {
          localStorage.setItem("userProfile", JSON.stringify(user));
          props.onStopLoad();
          props.onToggle();
        },
        (error) => {
          props.onStopLoad();
          notifyError(error);
        }
      );
    }
  };
  
  const responseFacebook = (response) => {
    props.onStartLoad();
    setPinOrPassword(null);

    authApi.loginFacebook(response.accessToken).then(
      (user) => {
        localStorage.setItem("userProfile", JSON.stringify(user));
        props.onStopLoad();
        props.onToggle();
      }, 
      (error) => { 
        props.onStopLoad();
        notifyError(error) 
      }
    );
  };

  const handleResetPassword = (event, errors, values) => { 
    if (errors.length === 0) {
      props.onStartLoad();

      authApi.resetPassword(phoneNoOrEmail).then(
        (user) => { 
          props.onStopLoad();
          notifyInfo("Your password is been reset, please check your email.") 
        },
        (error) => { 
          props.onStopLoad();
          notifyError(error); 
        }
      );
      props.onLoading(false);
    }
  };

  return (
    <div className="d-flex align-items-center" style={{height: "500px"}}>
      <NotifyContainer />
      <Row>
        <Col xs={12}>
          <Card
            className="login-page border-0 p-10"
            style={{ zIndex: "1" }}
          >
            <CardBody className="p-20">
            {
              screen === "intro-login" ?
                <AvForm className="login-form mt-2" onSubmit={handleLoginSelection}>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-5">
                        <Label className="form-label" htmlFor="email">
                          Your Email or Mobile No.{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <div className="form-icon position-relative">
                          <i>
                            <FeatherIcon
                              icon="user"
                              className="fea icon-sm icons"
                            />
                          </i>
                        </div>
                        <AvField
                          type="text"
                          className="form-control ps-5"
                          id="phoneNoOrEmail"
                          name="phoneNoOrEmail"
                          placeholder="Enter Email or Mobile No."
                          value={phoneNoOrEmail}
                          onChange={(e) => setPhoneNoOrEmail(e.target.value)}
                          required
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your email or mobile no.",
                            },
                            pattern: {
                              value:
                                "^([a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}|[0-9]{5,20})$",
                              errorMessage: "Your email or mobile no. is not valid.",
                            },
                          }}
                        />
                      </div>
                    </Col>

                    <Col lg={12} className="mb-0">
                      <div className="d-grid">
                        <Button color="primary">Login</Button>
                      </div>
                    </Col>

                    <Col lg={12} className="mt-4 text-center">
                      <h6>Or continue with</h6>
                      <Row>
                        <div className="col-12 mt-3">
                          <div className="d-grid">
                            <FacebookLogin
                              appId="1259596074550027"
                              callback={responseFacebook}
                              render={renderProps => (
                                <Link to="#" className="btn btn-light" onClick={renderProps.onClick}>
                                  <i className="mdi mdi-facebook text-primary"></i> Facebook
                                </Link>
                              )}
                            />
                          </div>
                        </div>

                        {/* <div className="col-6 mt-3">
                          <div className="d-grid">
                            <Link to="#" className="btn btn-light"><i className="mdi mdi-instagram text-danger"></i> Instagram</Link>
                          </div>
                        </div> */}

                        <Col xs="12" className="text-center mt-3">
                          <p className="mb-0 mt-3">
                            <small className="text-dark me-2">
                              Don't have an account ?
                            </small>{" "}
                            <Link
                              to="#"
                              className="text-dark fw-bold"
                              onClick={() => props.onTab("register")}
                            >
                              Sign Up
                            </Link>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </AvForm>
                : null
              }
              
              {
                screen === "email-login" ?
                  <LoginByEmail 
                    verifyAccount={props.verifyAccount?.type === "email"}
                    handleLoginEmail={handleLoginEmail}
                    setPinOrPassword={setPinOrPassword}
                    setScreen={setScreen}
                  />
                : null
              }

              {
                screen === "phone-login" ?
                  <LoginByMobile 
                    verifyAccount={props.verifyAccount?.type === "mobile"}
                    setPinOrPassword={setPinOrPassword}
                    setScreen={setScreen}
                    handleLoginPhone={handleLoginPhone}
                  />
                : null
              }

              {
                screen === "reset-password" ?
                <AvForm onSubmit={handleResetPassword}>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-5">
                        <Label className="form-label" htmlFor="email">
                          <h4>Forgot your password?</h4>
                          Just send us your registered email to reset password
                        </Label>
                        <div className="form-icon position-relative">
                          <i>
                            <FeatherIcon
                              icon="user"
                              className="fea icon-sm icons"
                            />
                          </i>
                        </div>
                        <AvField
                          type="text"
                          className="form-control ps-5"
                          id="email"
                          name="email"
                          placeholder="Enter Email"
                          value={emailForReset}
                          onChange={(e) => setEmailForReset(e.target.value)}
                          required
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your email",
                            },
                            pattern: {
                              value:
                                "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                              errorMessage: "Your email address is not valid.",
                            },
                          }}
                        />
                      </div>
                    </Col>

                    <Col lg={12} className="mb-0">
                      <div className="d-grid">
                        <Button color="primary">Reset Password</Button>
                      </div>
                    </Col>

                    <Col lg={12} className="mb-0">
                      <h6></h6>
                      <div className="col-12 mt-3">
                        <div className="d-grid">
                          <Link 
                            to="#" 
                            className="btn btn-light"
                            onClick={() => setScreen("intro-login")}
                          >
                            Back to Login Selection
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
                : null
              }
              
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}