import { Link } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import { Row, Col, } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import bleudine_logo from "../assets/images/bleudine_logo.png";

export default function UserProfile(props) {
  const [userProfile] = useLocalStorage("userProfile");
  
  const handleLogout = () => {
    localStorage.removeItem("userProfile");
    window.location.reload();
    props.onToggleUserProfile();
  }

  const openChangePassword = () => props.onToggleChangePassword();

  return (
    <>
      <Row>
        <Col md="12">
          <div className="border border-secondary m-2 p-4">
            <div className="d-flex align-items-center">
              <i>
                <FeatherIcon
                  icon="user"
                  className="fea icon-ex-md text-muted me-3"
                />
              </i>
              <div className="flex-1">
                <h6 className="mb-0 d-inline-block">Name :</h6>
                &nbsp;{userProfile?.firstName} {userProfile?.lastName}
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <i>
                <FeatherIcon
                  icon="mail"
                  className="fea icon-ex-md text-muted me-3"
                />
              </i>
              <div className="flex-1">
                <h6 className="mb-0 d-inline-block">Email :</h6>
                &nbsp;{userProfile?.email}
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <i>
                <FeatherIcon
                  icon="phone"
                  className="fea icon-ex-md text-muted me-3"
                />
              </i>
              <div className="flex-1">
                <h6 className="mb-0 d-inline-block">Cell No :</h6>
                &nbsp;{userProfile?.phoneNumber}
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <i>
                <FeatherIcon
                  icon="gift"
                  className="fea icon-ex-md text-muted me-3"
                />
              </i>
              <div className="flex-1">
                <h6 className="mb-0 d-inline-block">Birthday :</h6>
                &nbsp;<p className="text-muted mb-0"></p>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <i>
                <FeatherIcon
                  icon="users"
                  className="fea icon-ex-md text-muted me-3"
                />
              </i>
              <div className="flex-1">
                <h6 className="mb-0 d-inline-block">Membership :</h6>
                &nbsp;<img src={bleudine_logo} height="18px" alt="" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      
      {
        userProfile.accountType === 1 ?
          <Row>
            <Col md="12">
              <div className="m-2">
                <div className="d-flex align-items-center">
                  <Link 
                    to="#" 
                    className="btn btn-light w-100"
                    onClick={openChangePassword}
                  >
                    Change Password
                  </Link>
                </div>
              </div>
            </Col>
          </Row> : null
      }

      <Row>
        <Col md="12">
          <div className="m-2">
            <div className="d-flex align-items-center">
              <Link 
                to="#" 
                className="btn btn-light w-100"
                onClick={handleLogout}
              >
                Logout
              </Link>
            </div>
          </div>
        </Col>

        <Col md="12">
          <div className="m-2">
            <div className="d-flex align-items-center">
              <Link 
                to="#" 
                className="btn btn-light w-100"
                onClick={props.onToggleUserProfile}
              >
                Exit
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}