import React, { Component } from "react";
import { Container, Row, Col, } from "reactstrap";

import logolight from "../../assets/images/logo.png";
import logodark from "../../assets/images/logo.png";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className={this.props.isLight ? "footer bg-light" : "footer"}>
          <Container>
            <Row>
              <Col
                lg="4"
                xs="12"
                name="footercolumn"
                className="mb-0 mb-md-0 pb-0 pb-md-0 mx-auto"
                style={{textAlign: "center"}}
              >
                <img
                  src={this.props.isLight ? logodark : logolight}
                  height="24"
                  alt=""
                />
              </Col>
            </Row>
          </Container>
        </footer>
        <footer className="footer footer-bar">
          <Container className="text-center">
            <Row>
              <Col sm="6" className="mx-auto">
                <div className="text-sm-center">
                  <p className="mb-0">© 2022 Bleustay. All rights reserved</p>
                  <p className="mb-0">
                    <a
                      href="https://develab.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-reset"
                    >
                      Powered by Develab
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
