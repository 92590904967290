import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Label,
  Button,
  Card,
  CardBody,
} from "reactstrap";


import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { AvForm, AvField } from "availity-reactstrap-validation";
import { authApi } from "../api/authApi";
import FeatherIcon from "feather-icons-react";
import { NotifyContainer, notifyError, notifyInfo } from "../components/Shared/Notify";
import bleudine_logo from "../assets/images/bleudine_logo.png";

export default function Register(props) {
  const [signUpWithEmail, setSignUpWithEmail] = useState(true);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);

  const switchSignUpType = () => setSignUpWithEmail(!signUpWithEmail);
  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      props.onStartLoad();
      const accountType = signUpWithEmail ? 1 : 2; /// 1 - Email, 2 - Mobile

      authApi.signUpShopper(accountType, email, phoneNo, firstName, lastName).then(
        (user) => {
          props.onStopLoad();
          const payload = signUpWithEmail ? {type: "email", value: email} : {type: "mobile", value: phoneNo};
          props.onVerifyAccount(payload);
        }, 
        (error) => {
          props.onStopLoad();
          notifyError(error);
        }
      );
    }
  };

  return (
    <>
      <NotifyContainer />
      <Card className="login_page shadow rounded border-0" style={{height: "560px"}}>
        <CardBody>
          <h4>{ !signUpWithEmail ? "Sign Up with Mobile" : "Sign Up with Email" }</h4>
          <AvForm className="login-form mt-2" onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label" htmlFor="firstname">
                      First name <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon position-relative">
                    <i><FeatherIcon
                      icon="user"
                      className="fea icon-sm icons"
                    /></i>
                  </div>
                  <AvField
                    type="text"
                    className="form-control ps-5"
                    id="firstname"
                    name="firstname"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    errorMessage=""
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter first name",
                      },
                    }}
                  />
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label" htmlFor="lastname">
                    Last name <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon position-relative">
                    <i><FeatherIcon
                        icon="user-check"
                        className="fea icon-sm icons"
                    /></i>
                  </div>
                  <AvField
                    type="text"
                    className="form-control ps-5"
                    name="lastname"
                    id="lastname"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    errorMessage=""
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter last name",
                      },
                    }}
                  />
                </div>
              </Col>
            
              {
                signUpWithEmail ?
                <Col md="12">
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="email">
                      Your Email <span className="text-danger">*</span>
                    </Label>
                    <div className="form-icon position-relative">
                      <i><FeatherIcon
                        icon="mail"
                        className="fea icon-sm icons"
                      /></i>
                    </div>
                    <AvField
                      type="text"
                      className="form-control ps-5"
                      id="email"
                      name="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      errorMessage=""
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter your email",
                        },
                        pattern: {
                          value:
                          "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                          errorMessage: "E-Mail is not valid!",
                        },
                      }}
                    />
                    <span style={{fontSize: "0.8em"}}>You'll use this email address when you log in and if you ever need to reset your password.</span>
                  </div>
                </Col> :
                <Col md="12">
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="phoneNo">
                      Your Mobile No. <span className="text-danger">*</span>
                    </Label>
                    <div className="form-icon position-relative">
                      <i><FeatherIcon
                        icon="phone"
                        className="fea icon-sm icons"
                      /></i>
                    </div>
                    <PhoneInput
                      style={{width: "100%"}}
                      country={"id"}
                      value={phoneNo}
                      onChange={(value) => setPhoneNo(value)}
                    />
                    <span style={{fontSize: "0.8em"}}>You'll use this mobile no. when you log in.</span>
                  </div>
                </Col>
              }

              <Col md="12">
                <div className="mb-3">
                  <small>
                    By signing up, you agree to the{" "}
                    <Link to="#" className="text-primary" onClick={props.onToggleTerms}>
                      <strong>Terms & Condition</strong>
                    </Link>
                    {" "}and{" "}
                    <Link to="#" className="text-primary" onClick={props.onTogglePrivacy}>
                      <strong>Privary Policy</strong>
                    </Link>
                  </small>
                  {" "}<img src={bleudine_logo} height="16px" alt="" />
                </div>
              </Col>

              <Col md="12">
                <div className="d-grid">
                  <Button color="primary">Submit</Button>
                </div>
              </Col>

              <Col md="12" className="mt-1 text-center">
                <Row>
                  <div className="col-12 mt-3">
                    <div className="d-grid">
                      <Link 
                        to="#" 
                        className="btn btn-light"
                        onClick={switchSignUpType}
                      >
                        { signUpWithEmail ? "Sign Up with Mobile" : "Sign Up with Email" }
                      </Link>
                    </div>
                  </div>
                </Row>
              </Col>

              <Col xs="12" className="text-center mt-3">
                <p className="mb-0 mt-3">
                  <small className="text-dark me-2">
                    Already have an account ?
                  </small>{" "}
                  <Link
                    to="#"
                    className="text-dark fw-bold"
                    onClick={() => props.onTab("login")}
                  >
                    Sign In
                  </Link>
                </p>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
}
