import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withLocalize } from "react-localize-redux";

import {
  Container,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import flag_GBR from "../../assets/images/flags/GBR.png";
import flag_IDN from "../../assets/images/flags/IDN.png";

const LanguageToggle = (props) =>
{
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const language = localStorage.getItem("language");
  // if (language) {
  //   props.setActiveLanguage(language);
  // }

  return (
    <>
      <Link to="#" onClick={toggle}>
        <div 
          className="searchbtn btn btn-primary px-2 py-1 rounded w-100" 
          style={{height: "30px", fontSize: "0.8em"}}
        >
          {/* {
            language === "en" ?
              <><img src={flag_GBR} width="18" height="18" alt="" />&nbsp;English</>
            : 
              <><img src={flag_IDN} width="18" height="18" alt="" />&nbsp;Bahasa</>
          } */}
          <><img src={flag_GBR} width="18" height="18" alt="" />&nbsp;English</>
        </div>
      </Link>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Choose Language:
        </ModalHeader>
        <ModalBody>
          <Container>
            <ul className="list-unstyled mt-4 mb-0">
              <li>
                <div className="custom-control custom-radio custom-control-inline">
                  <div className="form-check mb-0">
                    <Input 
                      className="form-check-input" 
                      type="radio" 
                      id="english"
                      name="english"
                      checked={language === "en"}
                      onChange={() => {
                        localStorage.setItem("language", "en");
                        props.setActiveLanguage("en");
                      }}
                    />
                    <Label className="form-check-label" htmlFor="english">
                      <img src={flag_GBR} width="18" height="18" alt="" />&nbsp;English
                    </Label>
                  </div>
                </div>
              </li>

              {/* <li className="mt-3">
                <div className="form-check mb-0">
                  <Input 
                    className="form-check-input" 
                    type="radio" 
                    id="bahasa"
                    name="bahasa"
                    checked={language === "id"}
                    onChange={() => {
                      localStorage.setItem("language", "id");
                      props.setActiveLanguage("id");
                    }}
                  />
                  <Label className="form-check-label" htmlFor="bahasa">
                    <img src={flag_IDN} width="18" height="18" alt="" />&nbsp;Bahasa
                  </Label>
                </div>
              </li> */}
            </ul>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withLocalize(LanguageToggle);