import { useState } from "react";
import { Link } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import {
  Row,
  Col,
  Label,
  Button,
  Card,
  CardBody,
} from "reactstrap";

import { authApi } from "../api/authApi";
import FeatherIcon from "feather-icons-react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { NotifyContainer, notifyError, notifyInfo } from "../components/Shared/Notify";

export default function ChangePassword(props) {
  const [userProfile] = useLocalStorage("userProfile");
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);

  const handleChangePassword = (event, errors, values) => {
    if (errors.length === 0) {
      authApi.changePassword(userProfile.id, oldPassword, newPassword).then(
        (result) => { notifyInfo("Your password is successfully changed") }, 
        (error) => { notifyError(error) }
      );
    }
  }

  return (
    <div className="d-flex align-items-center" style={{height: "450px"}}>
      <NotifyContainer />
      <Row>
        <Col xs={12}>
          <Card
            className="login-page border-0 p-10"
            style={{ zIndex: "1" }}
          >
            <CardBody className="p-20">
              <AvForm className="login-form mt-2" onSubmit={handleChangePassword}>
                <Row>
                  <Col lg={12}>
                    <div className="mb-4">
                      <Label className="form-label" htmlFor="email">
                        Old Password{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <div className="form-icon position-relative">
                        <i>
                          <FeatherIcon
                            icon="user"
                            className="fea icon-sm icons"
                          />
                        </i>
                      </div>
                      <AvField
                        type="password"
                        className="form-control ps-5"
                        id="oldPassword"
                        name="oldPassword"
                        placeholder="Enter old password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                        errorMessage=""
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter your old password",
                          },
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="mb-5">
                      <Label className="form-label" htmlFor="password">
                        New Password{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <div className="form-icon position-relative">
                        <i>
                          <FeatherIcon
                            icon="lock"
                            className="fea icon-sm icons"
                          />
                        </i>
                      </div>
                      <AvField
                        type="password"
                        className="form-control ps-5"
                        id="newPassword"
                        name="newPassword"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        errorMessage=""
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Password",
                          },
                          minLength: {
                            value: 8,
                            errorMessage:
                              "Your password must be at least 8 characters",
                          },
                          // maxLength: {
                          //   value: 16,
                          //   errorMessage:
                          //     "Your password must be between 6 and 8 characters",
                          // },
                          pattern: {
                            value: "^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,}$",
                            errorMessage: "Password must be at least 3 of the following: upper case (A-Z), lower case (a-z), number (0-9) and special chars (e.g !@#$%^&*)",
                          },
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg={12} className="mt-4">
                    <div className="d-grid">
                      <Button color="primary">Submit</Button>
                    </div>
                  </Col>

                  <Col lg={12} className="mt-1 text-center">
                    <Row>
                      <div className="col-12 mt-3">
                        <div className="d-grid">
                          <Link 
                            to="#" 
                            className="btn btn-light" 
                            onClick={props.onToggle}
                          >
                            Back to My Profile
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}