import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Container } from "reactstrap";

import LanguageToggle from "./LanguageToggle";
import LoginRegisterButton from "./LoginRegisterButton";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      navLinks: [
        // { id: 1, title: "Home", link: "/index" },
        // {
        //   id: 2,
        //   title: "Docs",
        //   link: "/#",
        //   isOpenSubMenu: false,
        //   child: [
        //     { title: "Documentations", link: "/documentation" },
        //     { title: "Changelog", link: "/changelog" },
        //     { title: "Components", link: "/components" },
        //     { title: "Widget", link: "/widget" },
        //   ],
        // },
      ]
    };

    this.toggleLine = this.toggleLine.bind(this);
    this.openBlock.bind(this);
  }

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("top-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent1 = parent.parentElement;
      parent1.classList.add("active"); // li
      if (parent1) {
        const parent2 = parent1.parentElement;
        parent2.classList.add("active"); // li
        if (parent2) {
          const parent3 = parent2.parentElement;
          parent3.classList.add("active"); // li
          if (parent3) {
            const parent4 = parent3.parentElement;
            parent4.classList.add("active"); // li
          }
        }
      }
    }
  };

  openBlock = (level2_id) => {
    var tmpLinks = this.state.navLinks;
    tmpLinks.map((tmpLink) =>
      //Match level 2 id
      tmpLink.id === level2_id
        ? (tmpLink.isOpenSubMenu = !tmpLink.isOpenSubMenu)
        : false
    );
    this.setState({ navLinks: tmpLinks });
  };

  render () {
    return (
      <>
        <header id="topnav" className="defaultscroll sticky">
          <Container>
            <div className="logo">
              <img src={this.props.logo} height="50" width="90" className="logo-light-mode" alt="" />
            </div>
  
            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>

            <div 
              id="navigation"
              style={{ display: this.state.isOpen ? "block" : "none" }}
            >
              <ul className="navigation-menu" id="top-menu">
                {this.state.navLinks.map((navLink, key) =>
                  navLink.child ? (
                    <li className="has-submenu" key={key}>
                      <Link
                        to={navLink.link}
                        onClick={(event) => {
                          event.preventDefault();
                          this.openBlock(navLink.id);
                        }}
                      >
                        {navLink.title}
                      </Link>
                      
                      <span className="menu-arrow"></span>
                      
                      <ul
                        className={navLink.isOpenSubMenu ? "submenu open" : "submenu"}
                      >
                        {navLink.child.map((childArray, childKey) =>
                          <li key={childKey}>
                            <Link to={childArray.link}>
                              {childArray.title}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </li>
                  ) : (
                    <li key={key}>
                      <Link to={navLink.link}>{navLink.title}</Link>
                    </li>
                  )
                )}

                <li>
                  <LanguageToggle />
                </li>
                
                <li>
                  <LoginRegisterButton />
                </li>
              </ul>
            </div>
          </Container>
        </header>
      </>
    );
  }
}

export default withRouter(Topbar);