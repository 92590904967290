import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";
import PrintProvider, { NoPrint } from "react-easy-print";

const app = (
  <LocalizeProvider>
    <PrintProvider>
      <NoPrint>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </NoPrint>
    </PrintProvider>
  </LocalizeProvider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
