import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FeatherIcon from "feather-icons-react";

export default function LoginByEmail(props) {
  return (
    <AvForm className="mt-2" onSubmit={props.handleLoginEmail}>
      <Row>
        <Col lg={12}>
          <div className="mb-5">
          <h4>{ props.verifyAccount ? "Verify Your Account" : "Login with Email" }</h4>
            {
              props.verifyAccount ? 
                <div style={{fontSize: "0.8em", marginBottom: "15px"}}>
                  A verification email has been sent to you. Please use the temporary password for login.
                </div>
              : null
            }
            <Label className="form-label" htmlFor="password">
              Password
              <span className="text-danger">*</span>
            </Label>
            <div className="form-icon position-relative">
              <i>
                <FeatherIcon
                  icon="lock"
                  className="fea icon-sm icons"
                />
              </i>
            </div>
            <AvField
              type="password"
              className="form-control ps-5"
              name="password"
              id="password"
              placeholder="Enter password"
              onChange={(e) => props.setPinOrPassword(e.target.value)}
              required
              errorMessage=""
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter Password",
                },
              }}
            />
          </div>
        </Col>

        {
          props.verifyAccount ? null
          : <Col lg="12">
              <div className="d-flex justify-content-between">
                <div className="mb-3">
                  <div className="form-check">
                  </div>
                </div>
                <p className="forgot-pass mb-0">
                  <Link
                    to="#"
                    className="text-dark fw-bold"
                    onClick={() => props.setScreen("reset-password")}
                  >
                    Forgot password ?
                  </Link>
                </p>
              </div>
            </Col>
        }

        <Col lg={12} className="mb-0">
          <div className="d-grid">
            <Button color="primary">Submit</Button>
          </div>
        </Col>

        <Col lg={12} className="mb-0">
          <div className="col-12 mt-3">
            <div className="d-grid">
              <Link 
                to="#" 
                className="btn btn-light"
                onClick={() => props.setScreen("intro-login")}
              >
                Back to Login Selection
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </AvForm>
  );
}