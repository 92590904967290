import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollFixToTop = (props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", scrollNavigation, true);

    function scrollNavigation() {
      var doc = document.documentElement;
      var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      if (document.getElementById("topnav") != null) {
        if (top > 80) {
          document.getElementById("topnav").classList.add("nav-sticky");
        } else {
          document.getElementById("topnav").classList.remove("nav-sticky");
        }
      }
    };

    return function cleanup() {
      window.removeEventListener("scroll", scrollNavigation, true);
    }
  }, [location]);

  return <>{props.children}</>
};


export default ScrollFixToTop;