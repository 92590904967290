import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import PinInput from 'react-pin-input';
import OtpCountdown from "../components/Shared/OtpCountdown";

export default function LoginByMobile (props) {
  return (
    <AvForm>
      <Row>
        <Col lg={12}>
          <div className="mb-4">
            <h4>{ props.verifyAccount ? "Verify Your Account" : "Login with Phone" }</h4>
            <Label className="form-label" htmlFor="pin">
              A verification SMS has been sent to you.<br />
              Please use the 4 digit OTP to login :
            </Label>
            <div className="d-grid text-center">
              <PinInput 
                name="pin"
                length={4} 
                initialValue=""
                secret 
                onChange={(value, index) => {}} 
                type="numeric" 
                inputMode="number"
                style={{padding: '10px'}}  
                inputStyle={{borderColor: 'blue'}}
                inputFocusStyle={{borderColor: 'red'}}
                onComplete={(value, index) => {props.setPinOrPassword(value)}}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              <OtpCountdown onRequestOTP={props.requestOTP} />
            </div>
          </div>
        </Col>

        <Col lg={12} className="mb-0 mt-4">
          <div className="d-grid">
            <Button color="primary" onClick={props.handleLoginPhone}>
              Submit
            </Button>
          </div>
        </Col>

        <Col lg={12} className="mb-0">
          <div className="col-12 mt-3">
            <div className="d-grid">
              <Link 
                to="#" 
                className="btn btn-light"
                onClick={() => props.setScreen("intro-login")}
              >
                Back to Login Selection
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </AvForm>
  );
}