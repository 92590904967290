import { useState } from "react";
import { Link } from "react-router-dom";
import { 
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import useLocalStorage from "use-local-storage";
import LoginRegister from "../../pages/LoginRegister";
import UserProfile from "../../pages/UserProfile";
import ChangePassword from "../../pages/ChangePassword";

import login from "../../assets/images/signin.png";
import register from "../../assets/images/signup.png";
import user from "../../assets/images/user.png";


export default function LoginRegisterButton() {
  const [userProfile, setUserProfile] = useLocalStorage("userProfile");
  const [modalLogin, setModalLogin] = useState(false);
  const [modalProfile, setModalProfile] = useState(false);
  const [modalChangePassword, setModalChangePassword] = useState(false);
  const [modalTerms, setModalTerms] = useState(false);
  const [modalPrivacy, setModalPrivacy] = useState(false);

  const toggleLogin = () => {
    setModalLogin(!modalLogin);
    // const parsed = JSON.parse(localStorage.getItem("userProfile"));
    // setUserProfile(parsed);
  };

  const toggleProfile = () => {
    setModalProfile(!modalProfile);
    // const parsed = JSON.parse(localStorage.getItem("userProfile"));
    // setUserProfile(parsed);
  }

  const toggleChangePassword = () => setModalChangePassword(!modalChangePassword);
  const toggleTerms = () => {setModalTerms(!modalTerms)};
  const togglePrivacy = () => {setModalPrivacy(!modalPrivacy)};

  return (
    <>
      {
        userProfile ?
          <Link to="#" onClick={toggleProfile}>
            <div 
              className="searchbtn btn btn-primary px-2 py-1 rounded w-100" 
              style={{height: "30px", fontSize: "0.8em",lineHeight:"18px"}}
            >
              <img src={user} width="16" height="16" alt="" />&nbsp;{userProfile.firstName} {userProfile.lastName}
            </div>
          </Link>
        : <Link to="#" onClick={toggleLogin}>
            <div 
              className="searchbtn btn btn-primary px-2 py-1 rounded w-100"
              style={{
                height: "30px",
                fontSize: "0.8em",
                backgroundColor: "#722bc1"
              }}
            >
              <img src={login} width="16" height="16" alt="" />&nbsp;Login
              <span className="mx-2 text-dark">or</span>
              <img src={register} width="16" height="16" alt="" />&nbsp;Register
            </div>
          </Link>
      }
      

      <Modal isOpen={modalLogin} toggle={toggleLogin}>
        <ModalHeader toggle={toggleLogin}></ModalHeader>
        <ModalBody>
          <LoginRegister 
            onToggleLogin={toggleLogin} 
            onToggleTerms={toggleTerms}
            onTogglePrivacy={togglePrivacy}
          />
        </ModalBody>
      </Modal>


      <Modal isOpen={modalProfile} toggle={toggleProfile}>
        <ModalHeader toggle={toggleProfile}>My Profile</ModalHeader>
        <ModalBody>
          <UserProfile 
            onToggleUserProfile={toggleProfile} 
            onToggleChangePassword={toggleChangePassword} 
          />

          <Modal isOpen={modalChangePassword} toggle={toggleChangePassword}>
            <ModalHeader toggle={toggleChangePassword}>Change Password</ModalHeader>
            <ModalBody>
              <ChangePassword onToggle={toggleChangePassword} />
            </ModalBody>
          </Modal>
        </ModalBody>
      </Modal>

      <Modal fullscreen isOpen={modalTerms} toggle={toggleTerms}>
        <ModalHeader toggle={toggleTerms}>Terms & Conditions</ModalHeader>
        <ModalBody>
          <iframe
            title="terms"
            className="w-100 h-100"
            src={`${process.env.PUBLIC_URL}/html/termsConditions.html`}
          >
          </iframe>
        </ModalBody>
      </Modal>

      <Modal fullscreen isOpen={modalPrivacy} toggle={togglePrivacy}>
        <ModalHeader toggle={togglePrivacy}>Privacy Policy</ModalHeader>
        <ModalBody>
          <iframe
            title="privacy"
            className="w-100 h-100"
            src={`${process.env.PUBLIC_URL}/html/privacyPolicy.html`}
          >
          </iframe>
        </ModalBody>
      </Modal>
    </>
  );
}