import { Link } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";

export default function OtpCountdown(props) {
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return <span>OTP expires in {zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}</span>;
    }
  };

  const Completionist = () =>  
    <Link 
      to="#" 
      className="text-dark fw-bold" 
      onClick={props.onRequestOTP}
    >
      OTP was expired, click here generate again
    </Link>;

  return (
    <Countdown 
      date={Date.now() + 300000} 
      renderer={renderer}
    >
      <Completionist />
    </Countdown>
  );
}