import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";
const localhostPort = 5001;

export const authApi = {
  login,
  loginFacebook,
  getOTP,
  isLoggedIn,
  changePassword,
  resetPassword,
  signUpShopper,
};

function login(email, password) {
  const url = `${apiConfig.url}${apiConfig.path}/auth/login?platformSite=3`;
  //const url = `http://localhost:${localhostPort}/api/auth/login?platformSite=3`;
  const requestOptions = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json-patch+json",
    }),
    body: JSON.stringify({ emailOrPhone: email, password: password }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((user) => { return user; });
}

function loginFacebook(token) {
  const url = `${apiConfig.url}${apiConfig.path}/auth/loginFacebook/${token}`;
  //const url = `http://localhost:${localhostPort}/api/auth/loginFacebook/${token}`;

  const requestOptions = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json-patch+json",
    }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((user) => { return user; });
}


function getOTP(phoneNo) {
  const url = `${apiConfig.url}${apiConfig.path}/auth/IsSignedUp/${phoneNo}?platformSite=3`;
  //const url = `http://localhost:${localhostPort}/api/auth/IsSignedUp/${phoneNo}?platformSite=3`;

  const requestOptions = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json-patch+json",
    }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((otp) => { return otp; });
}

function isLoggedIn() {
  return localStorage.getItem("user") != null;
}

function changePassword(userId, oldPassword, newPassword) {
  const url = `${apiConfig.url}${apiConfig.path}/auth/newPassword`;
  //const url = `http://localhost:${localhostPort}/api/auth/newPassword`;

  const requestOptions = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json-patch+json",
    }),
    body: JSON.stringify({
      userId: userId,
      oldPassword: oldPassword,
      newPassword: newPassword,
    }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((user) => {
      if (user && user.token) {
        localStorage.setItem("userProfile", JSON.stringify(user));
      }
      return user;
    });
}

function resetPassword(email) {
  const url = `${apiConfig.url}${apiConfig.path}/auth/resetpassword/${email}`;
  //const url = `http://localhost:${localhostPort}/api/auth/resetpassword/${email}`;
  
  const requestOptions = { method: "POST" };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((user) => { return user; });
}

function signUpShopper(accountType, email, phoneNumber, firstName, lastName) {
  const url = `${apiConfig.url}${apiConfig.path}/shoppers/signup`;
  //const url = `http://localhost:${localhostPort}/api/shoppers/signup`;

  const requestOptions = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json-patch+json",
    }),
    body: JSON.stringify({
      accountType: accountType,
      email: email,
      phoneNumber: phoneNumber,
      firstName: firstName,
      lastName: lastName,
    }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((user) => {return user; });
}


