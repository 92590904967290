export const apiConfig = {
  url: window.location.href.includes("localhost") || window.location.href.includes("dev") ? "https://api.dev.bleudine.com" :
  window.location.href.includes("stg") ? "https://api.stg.bleudine.com" : "https://api.bleudine.com",
  path: "/api",
  getToken: function () {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.token : "";
  },
  getCurrentUserId: function () {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.id : 0;
  },
  getUserType: function () {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.type : 4;
  },
};
