import { gql } from '@apollo/client';

const GET_ROOMTYPE_BY_ID = gql`
  query GetRoomTypeById ($id: uuid!) {
    room_types(where: {id: {_eq: $id}}) {
      area
      bathroom_facilities
      bedroom_facilities
      default_occupancy
      description
      entertainment_facilities
      floor_facilities
      id
      images_url
      kitchen_facilities
      max_adults
      max_children
      max_occupancy
      name
      outdoor_facilities
      room_facilities
      room_type_beds {
        mattress_type
        qty
        length
        width
      }
      room_type_rates {
        price
        room_rate {
          is_ibe
          cancellation_policy {
            after_period_cancel_charge
            name
            no_show_cancel_charge
            period_cancel_without_charge
          }
          meal {
            name
            price
            service_charge
          }
        }
      }
    }
  }
`;


const GET_ROOMTYPE_BY_IDS = gql`
  query GetRoomTypeById ($ids: [uuid!]) {
    room_types(where: {id: {_in: $ids}}) {
      area
      bathroom_facilities
      bedroom_facilities
      default_occupancy
      description
      entertainment_facilities
      floor_facilities
      id
      images_url
      kitchen_facilities
      max_adults
      max_children
      max_occupancy
      name
      outdoor_facilities
      room_facilities
      room_type_beds {
        mattress_type
        qty
        length
        width
      }
      room_type_rates {
        id
        price
        tenant {
          locale {
            country
            currency_code
            decimal_seperator
            precision
            thousand_seperator
          }
            service_charge
        }
        room_rate {
          is_ibe
          cancellation_policy {
            after_period_cancel_charge
            name
            no_show_cancel_charge
            period_cancel_without_charge
          }
          meal {
            name
            price
            service_charge
          }
        }
      }
    }
  }
`;


const GET_ROOMTYPES_BY_TENANT_ID = gql`
  query GetRoomTypesByTenantId ($tenant_id: uuid!) {
    room_types(where: {tenant_id: {_eq: $tenant_id}}) {
      area
      bathroom_facilities
      bedroom_facilities
      default_occupancy
      description
      entertainment_facilities
      floor_facilities
      id
      images_url
      kitchen_facilities
      max_adults
      max_children
      max_occupancy
      name
      outdoor_facilities
      room_facilities
      room_type_beds {
        mattress_type
        qty
        length
        width
      }
      room_type_rates {
        price
        room_rate {
          is_ibe
          cancellation_policy {
            after_period_cancel_charge
            name
            no_show_cancel_charge
            period_cancel_without_charge
          }
          meal {
            name
            price
            service_charge
          }
        }
      }
    }
  }
`;


const GET_AVAILABLE_ROOMS = gql`
  query GetAvailableRooms($tenant_id: uuid!, $checkin: date!, $checkout: date!, $max_adults: Int, $max_children: Int ) {
    get_available_room_types(args: { p_tenant_id: $tenant_id, 
                                     p_check_in_date: $checkin, 
                                     p_check_out_date: $checkout, 
                                     p_max_adults: $max_adults, 
                                     p_max_children: $max_children },) {
      id
      name
      available_rooms
    }
  }
`;


export {
  GET_ROOMTYPE_BY_ID, 
  GET_ROOMTYPE_BY_IDS, 
  GET_ROOMTYPES_BY_TENANT_ID, 
  GET_AVAILABLE_ROOMS
};